import { useSelector } from 'react-redux';
import services from '../../services/servicesTeso';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useMensajesStore } from '../Generales_Mensajes_Validaciones/useMensajesStore';
import { useAuthStore } from '../useAuthStore';


export const useMessageStore = () => {
    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);
    const { SuccessMensaje, SuccessMensajeSend, SuccessDeleteMensaje, abrirCargar, abrirCargarNoTiempo, cerrarLoading, ErrorMensaje } = useMensajesStore();
    const { checkAuthToken } = useAuthStore();


    let userId = sessionStorage.getItem('uid');

     //Ingresa Data de manera masiva 
     const sendMessage = async () => {
        //debugger
        abrirCargarNoTiempo();
        const token = localStorage.getItem('token');
        checkAuthToken();
        try {
            let data = [];
            const headerAxios = {
                'content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'maxContentLength': Infinity, // Asegúrate de no limitar el tamaño del contenido
                'maxBodyLength': Infinity    // Asegúrate de no limitar el tamaño del cuerpo
                        }
            
                  data  = await axios.post(services.API.Message.sendMessage, { userId }, { headers: headerAxios });
            
            //const { data } = await axios.post(services.API.Banco.ApiTesoreria_Mant_Banco_Actualizar, { userId, id, name, description, status }, { headers: headerAxios });
            if (data.data.respuesta === "success") {
                cerrarLoading();
                console.log("data: " + data);
                
                SuccessMensajeSend();
                //dispatch(upSertBanco({ bancos: data.data }));
                //navigate('/pages/customer');
            }
            
        } catch (error) {
            console.log("error:" + error);
            Swal.fire(
                'Warning.',
                error.response.data.message,
                'question'
            )
        
        }
    }

    //Actualiza e inserta bancos. 
    const UpsertMessage = async ({ messageId, var_1, var_2, var_3, var_4 }) => {
        //debugger

        const token = localStorage.getItem('token');
        
        checkAuthToken();
        try {
            let data = [];
            const headerAxios = {
                'content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
                    }
                    
                  data  = await axios.post(services.API.Message.UpdateDataPayment, { userId, messageId, var_1, var_2, var_3, var_4 }, { headers: headerAxios });
            
                  if (data.data.respuesta === "success") {
                console.log("data: " + data);
                SuccessMensaje();
            }
            if (data.data.respuesta === "warning") {
                Swal.fire(
                    'Error',
                    'Contact Admin.',
                )
            }
        } catch (error) {
            console.log("error:" + error);
            ErrorMensaje();
        }
    }

    //Get Data to update.
    const getDataUpdateMessage = async () => {
        //debugger
        const token = localStorage.getItem('token');
        checkAuthToken();
        try {
            const headerAxios = {
                'content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
            abrirCargar();
            const { data } = await axios.post(services.API.Message.GetDataMessageWS, { userId }, { headers: headerAxios });
            if (data.respuesta === "success") {
                return data.Object
            }
        } catch (error) {
            console.log("error:" + error);
        }
    }

      //Get Data to update.
      const getDataUpdateMessageMonth = async () => {
        debugger
        const token = localStorage.getItem('token');
        checkAuthToken();
        try {
            const headerAxios = {
                'content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
            abrirCargar();
            const { data } = await axios.post(services.API.Message.GetDataMessageWSMonth, { userId }, { headers: headerAxios });
            if (data.respuesta === "success") {
                return data.Object
            }
        } catch (error) {
            console.log("error:" + error);
        }
    }

    return {
        //* Métodos
        UpsertMessage,
        getDataUpdateMessage,
        sendMessage,
        getDataUpdateMessageMonth
    }

}