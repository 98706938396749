import { useDispatch, useSelector } from 'react-redux';
import { calendarApi } from '../api';
import services from '../services/servicesTeso';
//import servicesIDI from '../../lizst/servicesIDI.js'
import { clearErrorMessage, onChecking, onLogin, onLogout, setPagosServicios,  upSertConfiguracionesPagos, 
    upSertPagosServicios,  setBoton,  setVerificarLlave,  setReportes,  setEstadoCargandoTrue, setEstadoCargandoFalse,  setPaisEmpresas,
     } from '../store';
import axios from 'axios';
import Swal from 'sweetalert2';


export const useAuthStore = () => {
   
    //debugger
    const { status, user, errorMessage, estadoCargando } = useSelector( state => state.auth );
    const dispatch = useDispatch();

    const startLogin = async({ email, password }) => {
        dispatch( onChecking() );
        limpiezaDatos();
        try {
            const { data } = await axios.post(services.API.Auth.ApiAutenticacion_Autorizacion, { email, password });
            //const { data } = await axios.post("http://localhost:4000/api/auth/login", { email, password });
            //http://143.198.139.80/
            console.log("ruta post:" + services.API.Auth.ApiAutenticacion_Autorizacion);
            if(data.respuesta){
                //LocalStorage               
                localStorage.setItem('token', data.usuario.token ); 
                localStorage.setItem('token-init-date', new Date().getTime() );
                localStorage.setItem('Name', data.usuario.name + " " + data.usuario.lastName);
                localStorage.setItem('payload', data.usuario.email);
                localStorage.setItem('sistema_nombre', "Pay Controller");
                localStorage.setItem('uid', data.usuario.id);  
                sessionStorage.setItem('user', data.usuario);  


                //SesionStorage
                sessionStorage.setItem('token', data.usuario.token );
                sessionStorage.setItem('token-init-date', new Date().getTime() );
                sessionStorage.setItem('Name', data.usuario.name + " " + data.usuario.lastName);
                sessionStorage.setItem('payload', data.usuario.email);
                sessionStorage.setItem('sistema_nombre', "Pay Controller");  
                sessionStorage.setItem('uid', data.usuario.id);  
                sessionStorage.setItem('user', data.usuario);  


                dispatch( onLogin({ name: data.usuario.name + " " + data.usuario.lastName, uid: data.usuario.id}) );        
                //const sistemasTesoreria = data2.find(sis_nombre === "Tesoreria");  
              // console.log(sistemasTesoreria);
            } else {
                dispatch( onLogout('Credenciales incorrectas') );
                setTimeout(() => {
                    dispatch( clearErrorMessage() );
                }, 10);
            }
        } catch (error) {
            console.log("error:" + error);
            dispatch( onLogout('Credenciales incorrectas') );
            setTimeout(() => {
                dispatch( clearErrorMessage() );
            }, 10);
        }
    }

    const startRegister = async({ usuario, password, name }) => {
        dispatch( onChecking() );
        try {
            const { data } = await calendarApi.post('/auth/new',{ usuario, password, name });
            localStorage.setItem('token', data.token );
            localStorage.setItem('token-init-date', new Date().getTime() );
            dispatch( onLogin({ name: data.name, uid: data.uid }) );
            
        } catch (error) {
            dispatch( onLogout( error.response.data?.msg || '--' ) );
            setTimeout(() => {
                dispatch( clearErrorMessage() );
            }, 10);
        }
    }

    const InicioSesioncheckAuthToken = async (nuevoToken, usuarioURL, pae_id) => {
        
        try {        
            limpiezaDatos();       
            //variables recibidas de la URL del Sistema Centralizado
            let token = nuevoToken;
            const USUARIO = usuarioURL;
            const paisId =  pae_id;

            //Header para el api
            const headerAxiosListar = {
                'Content-Type': 'application/json',
                'Authorization': token
            }
            
            //await esperaApi(2);
            //Consulta al API. Se da token y usuario AD para obtener datos personales y las opciones del menu
            const { data } = await axios.post(
                services.API.Auth.ApiAutenticacion_AutorizacionMC,
                { 
                    "sis_nombre": "Tesoreria",
                    //pae_id
                    pae_id
                }
                ,
                { headers: headerAxiosListar }
            );

            //await esperaApi(2);
            //Si la consulta es correcta, se guardan los nuevos datos en
            //LocalStorage, SesionStorage y Redux
            if(data.respuesta_tipo === "success"){
                 

                //Se obtiene el nombre del usuario logueado
                let nombreColaborador = data.data.usu_nombre;
                let usuario = data.data.usu_usuario;                
                const datosMenu = data.data.menu;
                localStorage.setItem('token', token );
                localStorage.setItem('token-init-date', new Date().getTime() );
                localStorage.setItem('Name', nombreColaborador);
                localStorage.setItem('payload', usuario);
                localStorage.setItem('sistema_nombre', 'tesoreria');
                localStorage.setItem('pais_id', pae_id);
                localStorage.setItem('datosOpcionesMenu', JSON.stringify(datosMenu));                

                //Se guardan datos en SesionStorage
                sessionStorage.setItem('token', token );
                sessionStorage.setItem('token-init-date', new Date().getTime() );
                sessionStorage.setItem('Name', nombreColaborador);
                sessionStorage.setItem('payload', usuario);
                sessionStorage.setItem('sistema_nombre', 'tesoreria');
                sessionStorage.setItem('pais_id', pae_id);
                sessionStorage.setItem('datosOpcionesMenu', JSON.stringify(datosMenu));

                dispatch( onLogin({ name: nombreColaborador, uid: usuario}));
                 
                //Se muestra modal confirmando el inicio de sesión
                Swal.fire(
                    'Exito al iniciar sesión!',
                    'Bienvenido al Sistema de Automatización de procesos de Tesoreria!',
                    'success'
                )

                         
            }
            //Si la consulta al api de warning
            else if(data.respuesta_tipo === "warning"){
              
                //Se muestra un modal notificando el fallo de inicio de sesión
                Swal.fire(
                    'Verify your data',
                    'Incorrect credentials',
                );          
                //Se cambia el status de Redux a logout para que se muestre el formulario de               
                dispatch( onLogout() );

            }
            //Se la consulta al api da error
            else if (data.respuesta === "error") {

                //Se setea en False para que se deje de mostrar el spinner de iniciar sesión           
                //Se muestra un modal con el error
                Swal.fire({
                    icon: 'error',
                    title: 'Error en BD',
                    text: data.respuesta_detalle,
                    footer: ''
                    }
                );
                dispatch( onLogout() );

            
            }

        // En caso de un error al intentar realizar la consulta al api
        }catch (error) {
           
            //Se cambia el status de Redux a logout para que se muestre el formulario de
            //inicio de sesión
            dispatch( onLogout() );

            //Se muestra un modal con el error
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error,
                footer: ''
              })

        }
    }

    const limpiezaDatos = () => {
        //Limpieza local y de sesion
        localStorage.clear();
        sessionStorage.clear();
        dispatch(upSertPagosServicios( [''] )) ;
        dispatch(setPagosServicios( [''] )) ;      
        dispatch(setBoton( [''] )) ;       
        dispatch(setVerificarLlave( [''] )) ;       
        dispatch(upSertConfiguracionesPagos( [''] )) ;        
         dispatch(setReportes( [''] )) ;
    }


    const checkAuthToken = async() => {
        //debugger
        const token2 = sessionStorage.getItem('token');
        const token = localStorage.getItem('token');
        if ( !token && !token2 ) return dispatch( onLogout() );

        try {   //Se recuperan los datos sesionStorage porque se pierden al recargar, de esta manera validamos que el token siga vigente y no cierre sesión al refrescar.
            //const { data } = await axios.post(services.API.Auth.ApiAutenticacion_Validacion, { token });
            //const { data } = await calendarApi.get('auth/renew');
            const nombre = sessionStorage.getItem('Name');
            const usuario = sessionStorage.getItem('payload');
            const sistema = sessionStorage.getItem('sistema_nombre');
            const user = sessionStorage.getItem('user');
            const id = sessionStorage.getItem('uid');


            if(!nombre){
                return dispatch( onLogout() );
            }
            
            localStorage.setItem('Name', nombre);
            localStorage.setItem('payload', usuario);
            localStorage.setItem('token', token2 );
            localStorage.setItem('token-init-date', new Date().getTime() );
            localStorage.setItem('sistema_nombre', sistema );
            //sessionStorage.clear();
            //dispatch( onLogout() );
            dispatch( onLogin({ name: nombre, uid: id }) );
        } catch (error) {
            localStorage.clear();
            dispatch( onLogout() );
        }
    }

    const verificaPais= async () => {
        try {  
            debugger      
             
            //variables recibidas de la URL del Sistema Centralizado
            let token = undefined;
            let sisId;           

            if(token == undefined){
                token = sessionStorage.getItem('token'); 
                sisId = sessionStorage.getItem('sistema_id');               
            }

            //Header para el api
            const headerAxiosListar = {
                'Content-Type': 'application/json',
                'Authorization': token
            }

            //await esperaApi(2);
            //Consulta al API. Se da token y usuario AD para obtener datos personales y las opciones del menu
            const { data } = await axios.post(
                services.API.Auth.ApiAutenticacion_Verificacion_Pais,
                { 
                    // sisId // id del sistema enviado por el token
                    "sis_id": 1,                    
                }
                ,
                { headers: headerAxiosListar }
            );

            //await esperaApi(2);
            //Si la consulta es correcta, se guardan los nuevos datos en
            //LocalStorage, SesionStorage y Redux
            if(data.respuesta_tipo === "success"){
                 

                //Se obtienen los datos de las empresas    
                
                let paisEmpresa = (data.data);
                console.log(JSON.stringify(data.data));

               // localStorage.setItem("Paises y empresas", paisEmpresa);
                localStorage.setItem('paisEmpresa', JSON.stringify(paisEmpresa));
                sessionStorage.setItem('paisEmpresa', JSON.stringify(paisEmpresa));                 

                dispatch(setPaisEmpresas({ data: data.data}));

                // return paisEmpresa; 
                
               // dispatch( onLogin({ name: nombreColaborador, uid: usuario}));   
                         
            }
            //Si la consulta al api de warning
            else if(data.respuesta_tipo === "warning"){
              
                //Se muestra un modal notificando el fallo de inicio de sesión
                Swal.fire(
                    'Verify your data',
                    'Incorrect credentials',
                );            
                //Se cambia el status de Redux a logout para que se muestre el formulario de               
                //dispatch( onLogout() );

            }
            //Se la consulta al api da error
            else if (data.respuesta_tipo === "error") {

                //Se setea en False para que se deje de mostrar el spinner de iniciar sesión           
                //Se muestra un modal con el error
                Swal.fire({
                    icon: 'error',
                    title: 'Error en BD',
                    text: data.respuesta_detalle,
                    footer: ''
                    }
                );
               // dispatch( onLogout() );

            
            }

        // En caso de un error al intentar realizar la consulta al api
        }catch (error) {
           
            //Se cambia el status de Redux a logout para que se muestre el formulario de
            //inicio de sesión
            dispatch( onLogout() );

            //Se muestra un modal con el error
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error,
                footer: ''
              })

        }
    }

    const startLogout = () => {
        localStorage.clear();
        sessionStorage.clear();
        limpiezaDatos();

        let uri_logout = "";

        switch (process.env.REACT_APP_API_AMBIENT) {
            case 'Dev':
                uri_logout = process.env.REACT_APP_LOGOUT_DEV;
                break;
            case 'Local':
                uri_logout = process.env.REACT_APP_LOGOUT_LOCAL;
                break;    
            case 'Prod':
                uri_logout = process.env.REACT_APP_LOGOUT_PROD;
                break;
        }
        //navigate('/auth/login');

        window.location.href = uri_logout;
        //console.log(uri_logout);
        //window.location.href = process.env.REACT_APP_LOGOUT_LOCAL;
        //window.location.href = 'https://10.161.252.108:3003';
        //dispatch(onLogout());
    }

    const abrirCargar = async() => {   
        Swal.fire({
            title: 'Cargando!',
            html: 'Un momento por favor.',
            timer: 2000,
            timerProgressBar: false,
            didOpen: () => {
              Swal.showLoading() 
            },
          })
    }



    return {
        //* Propiedades
        errorMessage,
        status, 
        user, 

        //* Métodos
        checkAuthToken,
        startLogin,
        startLogout,
        startRegister,
        verificaPais,
        abrirCargar,
        InicioSesioncheckAuthToken,
        estadoCargando,
    }

}