let api_route_teso = "";
let api_multi = "";

switch (process.env.REACT_APP_API_AMBIENT) {
    case 'Dev':
        api_multi = process.env.REACT_APP_API_DEV;
        break;
    case 'Local':
        api_multi = process.env.REACT_APP_LOCAL;
        break;    
    case 'UAT':
        api_multi = process.env.REACT_APP_UAT;
        break;
    case 'Prod':
        api_multi = process.env.REACT_APP_PROD;
        break;
}

let API = {
        Auth: {
            login_local:                                    "localhost:4000",
            ApiAutenticacion_Autorizacion:                 api_multi + "api/auth/login",
            ApiAutenticacion_Validacion:                   api_multi + "ApiAutenticacion_Validacion/FN",
            ApiAutenticacion_Usuario_Listar:               api_multi + "ApiAutenticacion_Usuario_Listar/FN",
            ApiAutenticacion_Usuario_Obtener:              api_multi + "ApiAutenticacion_Usuario_Obtener/FN",
            ApiAutenticacion_Usuario_Actualizar:           api_multi + "ApiAutenticacion_Usuario_Actualizar/FN",
            ApiAutenticacion_AutorizacionMC:               api_multi + "ApiAutenticacion_Inicio/Menu_Listar",
            ApiAutenticacion_Verificacion_Pais:            api_multi + "ApiAutenticacion_Inicio/MenuPae_Listar",
           
        },
        Category: {
            List:                                           api_multi + "api/category/list",
            Create:                                         api_multi + "api/category/create",
            Update:                                         api_multi + "api/category/update",
            Delete:                                         api_multi + "api/category/delete",
            GetData:                                        api_multi + "api/category/byId",

        },
        Supplier: {
            List:                                           api_multi + "api/supplier/list",
            Create:                                         api_multi + "api/supplier/create",
            Update:                                         api_multi + "api/supplier/update",
            Delete:                                         api_multi + "api/supplier/delete",
            GetData:                                        api_multi + "api/supplier/byId",

        },
        Warehouse: {
            List:                                           api_multi + "api/warehouse/list",
            Create:                                         api_multi + "api/warehouse/create",
            Update:                                         api_multi + "api/warehouse/update",
            Delete:                                         api_multi + "api/warehouse/delete",
            GetData:                                        api_multi + "api/warehouse/byId",
        },
        Customer: {
            List:                                           api_multi + "api/customer/list",
            Create:                                         api_multi + "api/customer/create",
            Update:                                         api_multi + "api/customer/update",
            Delete:                                         api_multi + "api/customer/delete",
            GetData:                                        api_multi + "api/customer/byId",
            GetDataPayment:                                 api_multi + "api/customer/byId",
            upload:                                         api_multi + "api/customer/upload",
            sendMessage:                                    api_multi + "api/customer/messageSendWS",
        },
        Payment: {
            GetDataPayment:                                 api_multi + "api/payment/byId",
            UpdateDataPayment:                              api_multi + "api/payment/update",
            GetDataMessageWS:                              api_multi + "api/payment/getDataMessage",

        },
        Message: {
            sendMessage:                                    api_multi + "api/message/messageSendWS",
            UpdateDataPayment:                              api_multi + "api/message/updateDataMessage",
            GetDataMessageWS:                               api_multi + "api/message/getDataMessage",
            GetDataMessageWSMonth:                          api_multi + "api/message/getDataMessageMonth",

        },
        Product: {
            List:                                           api_multi + "api/product/list",
            listDataForm:                                   api_multi + "api/product/listDataForm",
            Create:                                         api_multi + "api/product/create",
            Update:                                         api_multi + "api/product/update",
            Delete:                                         api_multi + "api/product/delete",
            GetData:                                        api_multi + "api/product/byId",
        },
        Inventory: {
            List:                                           api_multi + "api/inventory/list",
            Upsert:                                         api_multi + "api/inventory/upsert",
            listDataForm:                                   api_multi + "api/inventory/listDataForm",
            GetData:                                        api_multi + "api/inventory/byId",
            Delete:                                         api_multi + "api/inventory/delete",
        },
        InventoryProduct: {
            List:                                           api_multi + "api/inventoryProduct/list",
        },
        Invoice: {
            ListRG:                                           api_multi + "api/invoice/listENC",
        }
    }
    export default {
        API: API
    }